import React, { useState, useEffect } from "react";
import GeneralistBall from "../../assets/GeneralistBall.avif";
import PhysicalBall from "../../assets/PhysicalBall.avif";
import WindBall from "../../assets/WindBall.avif";
import PoisonBall from "../../assets/PoisonBall.avif";
import DisciplineBall from "../../assets/DisciplineBall.avif";
import RockBall from "../../assets/RockBall.avif";
import BugBall from "../../assets/BugBall.avif";
import TrickyBall from "../../assets/TrickyBall.avif";
import SteelBall from "../../assets/SteelBall.avif";
import FireBall from "../../assets/FireBall.avif";
import WaterBall from "../../assets/WaterBall.avif";
import EntangleBall from "../../assets/EntangleBall.avif";
import LightningBall from "../../assets/LightningBall.avif";
import CerebralBall from "../../assets/PsychicBall.avif";
import IceBall from "../../assets/IceBall.avif";
import NullifyBall from "../../assets/NullifyBall.avif";
import TeamworkBall from "../../assets/TeamworkBall.avif";
import "../../App.css";
import { typeColors } from "./TypeContainer.js";
import { typeDescriptions, typeTables } from "./typeData.js"; // Import the data

const Types = () => {
  const [selectedType, setSelectedType] = useState(1);
  const [headerColor, setHeaderColor] = useState(
    typeColors[1]?.color || "linear-gradient(#fff, #fff)"
  );
  const [selectedTab, setSelectedTab] = useState(1); // 1 for Type Description, 2 for Offensive/Defensive Indicators

  const firstRowImages = [
    GeneralistBall,
    PhysicalBall,
    WindBall,
    PoisonBall,
    DisciplineBall,
    RockBall,
    BugBall,
    TrickyBall,
  ];

  const secondRowImages = [
    SteelBall,
    FireBall,
    WaterBall,
    EntangleBall,
    LightningBall,
    CerebralBall,
    IceBall,
    NullifyBall,
    TeamworkBall,
  ];

  const handleImageClick = (index) => {
    setSelectedType(index + 1);
    setHeaderColor(
      typeColors[index + 1]?.color || "linear-gradient(#fff, #fff)"
    );
  };
  const renderContent = () => {
    if (selectedTab === 1) {
      return typeDescriptions[selectedType - 1].map((line, index) => (
        <p key={index} dangerouslySetInnerHTML={{ __html: line }} />
      ));
    } else if (selectedTab === 2) {
      const typeText = typeColors[selectedType]?.text || `Type ${selectedType}`;
      const headerBackgroundColor = typeColors[selectedType]?.colorC || "#444";
      const rowBackgroundColor = typeColors[selectedType]?.colorB || "#2e2e2e";
      const rowTextColor = typeColors[selectedType]?.colorD || "#fff";

      const filteredOffensiveRows = typeTables[selectedType - 1].filter(
        (row) => row.header && row.header.trim() !== ""
      );
      const filteredDefensiveRows = typeTables[selectedType - 1].filter(
        (row) => row.value && row.value.trim() !== ""
      );

      return (
        <div className="table-container">
          <div
            className="offensive-table-container"
            style={{
              backgroundColor: rowTextColor,
              borderColor: rowTextColor,
            }}
          >
            <table className="table">
              <thead>
                <tr>
                  <th
                    style={{
                      backgroundColor: headerBackgroundColor,
                      borderColor: headerBackgroundColor,
                    }}
                  >
                    {`${typeText} Off. Indicators`}
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredOffensiveRows.map((row, index) => (
                  <tr
                    key={`offensive-${index}`}
                    style={{
                      borderColor: headerBackgroundColor,
                      color: "black",
                    }}
                  >
                    <td
                      style={{
                        backgroundColor: rowBackgroundColor,
                        borderColor: headerBackgroundColor,
                      }}
                      dangerouslySetInnerHTML={{ __html: row.header }}
                    />
                  </tr>
                ))}
              </tbody>
              <tr></tr>
            </table>
          </div>
          <div
            className="defensive-table-container"
            style={{
              backgroundColor: rowTextColor,
              borderColor: rowTextColor,
            }}
          >
            <table className="table">
              <thead>
                <tr>
                  <th
                    style={{
                      backgroundColor: headerBackgroundColor,
                      borderColor: headerBackgroundColor,
                    }}
                  >
                    {`${typeText} Def. Indicators`}
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredDefensiveRows.map((row, index) => (
                  <tr
                    key={`defensive-${index}`}
                    style={{
                      borderColor: headerBackgroundColor,
                      color: "black",
                    }}
                  >
                    <td
                      style={{
                        backgroundColor: rowBackgroundColor,
                        borderColor: headerBackgroundColor,
                      }}
                      dangerouslySetInnerHTML={{ __html: row.value }}
                    />
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    setHeaderColor(
      typeColors[selectedType]?.color || "linear-gradient(#fff, #fff)"
    );
  }, [selectedType]);
  const rowBackgroundColor = typeColors[selectedType]?.colorB || "#2e2e2e";
  /*const rowTextColor = typeColors[selectedType]?.colorD || "#fff";*/
  const headerBackgroundColor = typeColors[selectedType]?.colorC || "#444";

  return (
    <div className="container">
      <div
        className="type-rows"
        style={{ border: `2px solid ${headerBackgroundColor}` }}
      >
        <div className="row">
          {firstRowImages.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Type ${index + 1}`}
              className={`type-image ${
                selectedType === index + 1 ? "active" : ""
              }`}
              onClick={() => handleImageClick(index)}
            />
          ))}
        </div>
        <div className="row">
          {secondRowImages.map((image, index) => (
            <img
              key={index + 8}
              src={image}
              alt={`Type ${index + 9}`}
              className={`type-image ${
                selectedType === index + 9 ? "active" : ""
              }`}
              onClick={() => handleImageClick(index + 8)}
            />
          ))}
        </div>
      </div>
      <div className="type-description-container">
        <div
          className="type-header"
          style={{
            background: headerColor,
            border: `2px solid ${headerBackgroundColor}`,
          }}
        >
          <h2>{typeColors[selectedType]?.text || `Type ${selectedType}`}</h2>
        </div>
        <div
          className="tabs"
          style={{
            borderLeft: `2px solid ${headerBackgroundColor}`,
            borderRight: `2px solid ${headerBackgroundColor}`,
          }}
        >
          <div
            className={`tab ${selectedTab === 1 ? "active" : ""}`}
            onClick={() => setSelectedTab(1)}
            style={{
              background:
                selectedTab === 1 ? rowBackgroundColor : headerBackgroundColor,
            }}
          >
            Type Description
          </div>
          <div
            className={`tab ${selectedTab === 2 ? "active" : ""}`}
            onClick={() => setSelectedTab(2)}
            style={{
              background:
                selectedTab === 2 ? rowBackgroundColor : headerBackgroundColor,
            }}
          >
            Offensive / Defensive Indicators
          </div>
        </div>
        <div
          className="type-description"
          style={{
            border: `2px solid ${headerBackgroundColor}`,
          }}
        >
          <div className="descriptionCont">{renderContent()}</div>
        </div>
      </div>
    </div>
  );
};

export default Types;
