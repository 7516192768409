// src/components/TitleBar/TitleBar.js

import React from "react";
import "../../App.css"; // Create and import a separate CSS file for TitleBar

const TitleBar = ({ title, comp }) => {
  const getFontSize = (text, isTitle) => {
    // Define font sizes based on whether it's the title or comp
    if (isTitle) {
      if (text.length > 20) return "27px";
      return "33px"; // Fixed size for primary title
    } else {
      return "18px"; // Fixed size for secondary title
    }
  };

  return (
    <div className="title-container">
      {/* Primary Title */}
      <div className="title-bar">
        <span
          className="title-text primary-title"
          style={{
            fontSize: getFontSize(title, true),
          }}
        >
          {title}
        </span>
      </div>

      {/* Secondary Title (Rendered only if 'comp' is provided) */}
      {comp && (
        <div className="title-bar">
          <span
            className="title-text secondary-title"
            style={{
              fontSize: getFontSize(comp, false),
            }}
          >
            {comp}
          </span>
        </div>
      )}
    </div>
  );
};

export default TitleBar;
