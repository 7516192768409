import React, { useState, useEffect } from "react";
import groupsData from "../../data/groupsData";
import GroupContainer from "./GroupContainer";
import GroupContainerMobile from "./GroupContainerMobile";
import "../../App.css";
import "./Okaymon.css";
import "./GroupContainerMobile.css";

function Okaymon() {
  const [groups, setGroups] = useState({});
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setGroups(groupsData); // Load the organized data

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Set for mobile when <= 768px
    };

    // Initial check
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="container">
      {isMobile
        ? Object.entries(groups).map(([groupId, groupData]) => (
            <GroupContainerMobile key={groupId} groupData={groupData} />
          ))
        : Object.entries(groups).map(([groupId, groupData]) => (
            <GroupContainer key={groupId} groupData={groupData} />
          ))}
    </div>
  );
}

export default Okaymon;
