import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SidebarLeft from "./components/SidebarLeft/SidebarLeft";
import MobileHeader from "./components/SidebarLeft/MobileHeader";
import SidebarRight from "./components/SidebarRight/SidebarRight";
import VideoSection from "./components/FrontPage/VideoSection";
import Intro from "./components/FrontPage/Intro";
import ThemeSwitch from "./components/ThemeSwitch/ThemeSwitch";
import Okaymon from "./components/Okaymon/Okaymon";
import Types from "./components/Types/Types";
import FAQs from "./components/FAQs/FAQs";
import Contact from "./components/Contact/Contact";
/*import CustomGrid from "./components/CustomGrid/CustomGrid";
import Footer from "./components/Footer";
*/

import "./App.css";

function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    checkScreenSize();

    window.addEventListener("resize", checkScreenSize);

    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  return (
    <Router>
      <div className="main-layout">
        {isMobile ? <MobileHeader /> : <SidebarLeft />}

        <div className="container">
          <Routes>
            <Route
              path="/"
              element={
                <div className="content-wrapper">
                  <VideoSection />
                  <Intro />
                </div>
              }
              exact
            />
            <Route path="/Okaymon" element={<Okaymon />} />
            <Route path="/types" element={<Types />} />
            <Route path="/faqs" element={<FAQs />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>
        {/* Pass isMobile to SidebarRight */}
        <SidebarRight isMobile={isMobile} />
        <ThemeSwitch />
      </div>
    </Router>
  );
}

export default App;
