import React, { useState } from "react";
import FAQs2 from "./FAQs2"; // Formerly the FAQ component

const FAQs = () => {
  const [faqs, setFaqs] = useState([
    {
      question: "Are these cards for sale?",
      answer: (
        <p>
          I'm not selling anything right now but maybe in the future when more
          of the project is completed and it seems like people have interest in
          that, I'll take a look at doing a print run of cards, or art prints of
          the illustrations or something like that!
        </p>
      ),
      open: false,
    },
    {
      question: "Is this a real game I can play?",
      answer: (
        <p>
          No it's not a trading card game or video game or anything like that,
          just something to look at.
        </p>
      ),
      open: true,
    },
    {
      question: "Are you looking for more illustrators for the player cards?",
      answer: (
        <p>
          I'm always interested in bringing more illustrators to the project!
          Feel free to shoot me an e-mail at evan@okaymon.com with your
          portfolio and whatever else you'd like to throw out there - can go to
          the contact page and shoot me a message through that to make it easy
          too.
        </p>
      ),
      open: false,
    },
    {
      question:
        "Do you have all 151 players done or just the ones on the players page?",
      answer: (
        <p>
          All 151 players have been mapped out already and are gonna be released
          when their art is done.
        </p>
      ),
      open: false,
    },
  ]);

  const toggleFAQ = (index) => {
    setFaqs(
      faqs.map((faq, i) => {
        faq.open = i === index ? !faq.open : false;
        return faq;
      })
    );
  };

  return (
    <div className="faq-container">
      <div className="faqs">
        {faqs.map((faq, index) => (
          <FAQs2 faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
        ))}
      </div>
    </div>
  );
};

export default FAQs;
