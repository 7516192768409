import React from "react";

// Define type colors (you can expand this as needed)
export const typeColors = {
  1: {
    color: "linear-gradient(#a8a878,#8a8a59)",
    colorB: "#a8a878",
    colorC: "#676742",
    colorD: "#3e3e28",
    text: "Generalist",
  },
  2: {
    color: "linear-gradient(#c03028,#9d2721)",
    colorB: "#c03028",
    colorC: "#7f1f1a",
    colorD: "#551511",
    text: "Physical",
  },
  3: {
    color: "linear-gradient(#a890f0,#9180c4)",
    colorB: "#a890f0",
    colorC: "#6a55aa",
    colorD: "#453e5b",
    text: "Separator",
  },
  4: {
    color: "linear-gradient(#a040a0,#803380)",
    colorB: "#9f51d7",
    colorC: "#9141CB",
    colorD: "#5E2A84",
    text: "Isolation",
    sparkles: "./assets/sparkles.gif", // Path for type-specific sparkles
    holo: "./assets/foil.png",
  },
  5: {
    color: "linear-gradient(#d4a82f,#94751e)",
    colorB: "#d4a82f",
    colorC: "#94751e",
    colorD: "#554311",
    text: "Discipline",
  },
  6: {
    color: "linear-gradient(#b8a038,#93802d)",
    colorB: "#b8a038",
    colorC: "#817028",
    colorD: "#4e4418",
    text: "Stonewall",
  },
  7: {
    color: "linear-gradient(#a8b820,#8d9a1b)",
    colorB: "#B8C26A",
    colorC: "#91A119",
    colorD: "#5E6910",
    text: "Pest",
  },
  8: {
    color: "linear-gradient(#705898,#554374)",
    colorB: "#A284A2",
    colorC: "#704170",
    colorD: "#492A49",
    text: "Tricky",
  },
  9: {
    color: "linear-gradient(#b8b8d0,#9797ba)",
    colorB: "#C1C2C1",
    colorC: "#9FA19F",
    colorD: "#676967",
    text: "Switcher",
  },
  10: {
    color: "linear-gradient(#f08030,#dd6610)",
    colorB: "#FFAC59",
    colorC: "#FF8000",
    colorD: "#A65300",
    text: "Heat Check",
  },
  11: {
    color: "linear-gradient(#6890f0,#386ceb)",
    colorB: "#74ACF5",
    colorC: "#2980EF",
    colorD: "#1B539B",
    text: "Flow",
  },
  12: {
    color: "linear-gradient(#78c850, #5ca935)",
    colorB: "#82C274",
    colorC: "#3FA129" /* 100% 2nd bar 33 */,
    colorD: "#29691B" /* 100% 2nd bar 20 */,
    text: "Entangle",
    sparkles: "./assets/sparkles.gif", // Path for type-specific sparkles
    holo: "./assets/foil.png",
  },
  13: {
    color: "linear-gradient(#f8d030,#f0c108)",
    colorB: "#FCD659",
    colorC: "#FAC000",
    colorD: "#A37D00",
    text: "Highlight",
  },
  14: {
    color: "linear-gradient(#f85888,#f61c5d)",
    colorB: "#F584A8",
    colorC: "#EF4179",
    colorD: "#9B2A4F",
    text: "Cerebral",
  },
  15: {
    color: "linear-gradient(#98d8d8,#69c6c6)",
    colorB: "#81DFF7",
    colorC: "#3DCEF3",
    colorD: "#28869E",
    text: "Clutch",
  },
  16: {
    color: "linear-gradient(#7038f8,#4c08ef)",
    colorB: "#8D98EC",
    colorC: "#5060E1",
    colorD: "#343E92",
    text: "Nullify",
  },
  17: {
    color: "linear-gradient(#f98cff,#f540ff)",
    colorB: "#F5A2F5",
    colorC: "#EF70EF",
    colorD: "#9B499B",
    text: "Teamwork",
  },
};

const TypeContainer = ({ types }) => {
  // Filter out null or undefined types
  const validTypes = types.filter(
    (type) => type !== null && type !== undefined
  );

  return (
    <div className="type-container">
      {validTypes.map((type, index) => {
        const typeInfo = typeColors[type] || {
          color: "",
          colorB: "",
          colorC: "",
          text: type,
        };
        return (
          <div
            key={index}
            className="type-item"
            style={{ background: typeInfo.color }}
          >
            {typeInfo.text}
          </div>
        );
      })}
    </div>
  );
};

export default TypeContainer;
